<template>
  <div>
    <div class="mb-6">
      <PageHeading v-if="!assessmentIsNewRecord">
        <span class="qa-assessment-title">
          {{ assessment.name }}
        </span>
        <template #actions>
          <div class="flex flex-row space-x-4">
            <Tooltip
              :enabled="!currentUserIsAssignedToAssessment"
              :debounce="200"
            >
              <template #parent>
                <BaseButton
                  icon="ExternalLinkIcon"
                  :as="'a'"
                  :is-disabled="!currentUserIsAssignedToAssessment"
                  :is-primary="false"
                  :target="'_blank'"
                  :href="currentUserIsAssignedToAssessment ? observerViewAssessmentsPath(assessment) : null"
                >
                  <slot>
                    {{ $t('components.assessment_form.open_assessment') }}
                  </slot>
                </BaseButton>
              </template>

              <div
                v-dompurify-html:link="$t('components.assessment_form.open_assessment_popover_text',
                                          {href: newObserverAssignmentsPath})"
              />
            </Tooltip>

            <BaseButton
              icon="CheckIcon"
              :is-loading="submitting"
              :is-disabled="false"
              qa-class="save-button"
              @click="modalOrSubmit"
            >
              <slot>
                {{ $t('defaults.save') }}
              </slot>
            </BaseButton>
          </div>
        </template>
      </PageHeading>
      <RescheduleModal
        v-model:selected="eventsRescheduleType"
        :open="showRescheduleModal"
        :change-options="changeOptions"
        :is-submitting="submitting"
        @close="showRescheduleModal = false;"
        @click="submitAssessmentForm"
      />
      <!-- Modals -->
      <ConfirmDialogModal
        :open="showWarningModal"
        :title="$t('components.assessment_form.reschedule_modal.options.end_date_overlaps.title')"
        :message="$t('components.assessment_form.reschedule_modal.options.end_date_overlaps.text')"
        @close="showWarningModal = false"
        @confirm="submitAssessmentForm"
      />
      <CompetencySelectionModal
        v-if="showCompetencySelectionModal"
        :competency-clusters="selectedCompetenceModel?.competencyClusters"
        :selected-competencies="selectedCompetencies"
        :open="showCompetencySelectionModal"
        :should-submit-selection="false"
        @close="showCompetencySelectionModal = false"
        @save-selection="saveCompetencySelection"
      />
      <ReportTemplateSelectionModal
        v-if="assessmentIsNewRecord && showReportTemplateSelectionModal"
        :available-report-templates="availableReportTemplates"
        :selected-report-templates-list="selectedReportTemplates"
        :open="showReportTemplateSelectionModal"
        @close="showReportTemplateSelectionModal = false"
        @select-report-templates="selectReportTemplates"
      />
    </div>
    <form
      ref="assessmentForm"
      :action="actionUrl"
      accept-charset="UTF-8"
      method="post"
      enctype="multipart/form-data"
      @submit="submitting = true"
      @keydown.enter.prevent
    >
      <input
        type="hidden"
        name="_method"
        :value="dataMethod"
      >
      <input
        type="hidden"
        name="authenticity_token"
        :value="$csrfToken"
      >
      <input
        type="hidden"
        name="events_reschedule_type"
        :value="eventsRescheduleType"
      >
      <div class="space-y-5 mb-5">
        <TwoColumnCard
          :header="$t('components.assessment_form.categories.general.header')"
          :helptext="$t('components.assessment_form.categories.general.helptext')"
        >
          <div class="space-y-6">
            <BaseInput
              v-model="assessment.name"
              :disabled="assessment.isCompleted"
              name="assessment[name]"
              type="text"
              :error="getError('name')"
              :label="$t('activerecord.attributes.assessment.name')"
              qa-class="assessment-name"
            />
            <BaseInput
              v-model="assessment.targetPosition"
              :disabled="assessment.isCompleted"
              name="assessment[target_position]"
              type="text"
              :error="getError('target_position')"
              :label="$t('activerecord.attributes.assessment.target_position')"
              qa-class="assessment-target-position"
            />
            <SearchableSelectList
              id="organisation-model-select"
              :selected="currentSelectedOrganisation"
              :options="availableOrganisations"
              :options-empty-message="$t('components.organisations.no_organisations')"
              value-prop="id"
              label-prop="displayName"
              name="assessment[organisation_id]"
              :disabled="organisationSelectionDisabled"
              :allow-reset="false"
              :error="getError('organisation')"
              :label="$t('activerecord.attributes.assessment.organisation')"
              qa-class="assessment-organisation-select"
              @update:selected="currentSelectedOrganisation = $event"
            />
            <ScheduleFields
              v-model:schedule-data="schedule"
              :disabled="!assessment.isEditable"
              :errors="errors"
            />
            <BaseTextarea
              v-model="assessment.description"
              :disabled="assessment.isCompleted"
              name="assessment[description]"
              :error="getError('description')"
              :label="$t('activerecord.attributes.assessment.description')"
              :hint="$t('defaults.optional')"
              qa-class="assessment-description"
            />
            <div
              v-if="assessmentIsNewRecord"
              class="space-y-5"
            >
              <div class="flex justify-between items-end gap-6">
                <input
                  type="hidden"
                  name="assessment[selected_competencies]"
                  :value="JSON.stringify(selectedCompetencies)"
                >
                <SearchableSelectList
                  id="competence-model-select"
                  :selected="selectedCompetenceModel"
                  :options="availableCompetenceModels"
                  value-prop="id"
                  label-prop="name"
                  secondary-label-prop="organisationDisplayName"
                  name="assessment[competence_model_id]"
                  :disabled="competenceModelIsIndividual"
                  :error="getError('competence_model_id')"
                  :label="$t('activerecord.attributes.assessment.competence_model')"
                  :hint="$t('defaults.optional')"
                  @update:selected="selectedCompetenceModel = $event"
                />
                <BaseButton
                  :is-disabled="!isSelectable"
                  :is-primary="true"
                  @click="showCompetencySelectionModal = true"
                >
                  {{ $t('defaults.customize') }}
                </BaseButton>
              </div>
              <div>
                <input
                  type="hidden"
                  name="assessment[report_templates_ids]"
                  :value="JSON.stringify(selectedReportTemplates)"
                >
                <BaseInput
                  v-if="reportTemplatesFeatureEnabled"
                  v-model="selectedReportTemplateNames"
                  :disabled="false"
                  type="text"
                  :label="$t('activerecord.models.reports.other')"
                  trailing-icon="SelectorIcon"
                  :readonly="true"
                  :as-button="true"
                  :hint="$t('defaults.optional')"
                  @click="showReportTemplateSelectionModal = true"
                />
              </div>
            </div>
          </div>
        </TwoColumnCard>
        <AssessmentWorkflowSettingsCard
          :assessment-workflow-settings="assessment.assessmentWorkflowSetting"
          :assessment-completed-at="assessment.completedAt"
        />
        <TwoColumnCard
          v-if="assessmentIsNewRecord"
          :header="$t('components.assessment_form.categories.features.header')"
          :helptext="$t('components.assessment_form.categories.features.helptext')"
        >
          <AssessmentFeaturesForm
            v-if="!assessment.id"
            v-model:initial-features="assessment.assessmentFeatures"
            :assessment-id="assessment.id"
            class="mb-2"
          >
            <template #toggle="{ feature, featureEnabled, toggleFeature }">
              <input
                type="hidden"
                :name="`assessment[assessment_features_attributes][${feature}]`"
                :value="featureEnabled ? 1 : 0"
              >
              <toggle
                :model-value="featureEnabled"
                @update:model-value="toggleFeature(feature)"
              />
            </template>
          </AssessmentFeaturesForm>
        </TwoColumnCard>
        <TwoColumnCard
          :header="$t('components.assessment_form.categories.branding.header')"
          :helptext="$t('components.assessment_form.categories.branding.helptext')"
        >
          <div class="space-y-6">
            <SelectList
              v-model:selected="brandingSource"
              :label="$t('activerecord.attributes.assessment.branding_source')"
              :options="brandingSources"
              name="assessment[branding_source]"
              :error="getError('branding_source')"
              :disabled="assessment.isCompleted"
            />

            <ColorPicker
              v-if="brandingIsActive"
              v-model="assessment.color"
              name="assessment[color]"
              :disabled="!brandingIsActive || assessment.isCompleted"
              :error="getError('color')"
            />
            <ColorPicker
              v-else
              v-model="currentBrandingColor"
              :hide-picker="true"
              :disabled="true"
            />
            <input
              v-if="!brandingIsActive"
              type="hidden"
              name="assessment[color]"
              :value="null"
            >
            <SingleFileUpload
              v-if="brandingIsActive"
              :file="assessment.logo"
              accepted-mime-types="image/png, image/jpeg"
              accepted-file-types="PNG / JPG"
              name="assessment[logo]"
              destroy-name="destroy_logo"
              :disabled="!brandingIsActive || assessment.isCompleted"
              :error="getError('logo')"
            />
            <BrandingShowcase
              v-if="!brandingIsActive"
              :logo="currentBrandingLogo"
              :source="brandingSource.value"
            />
            <input
              v-if="!brandingIsActive"
              type="hidden"
              name="destroy_logo"
              value="1"
            >
          </div>
        </TwoColumnCard>
        <div
          v-if="assessmentIsNewRecord"
          class="flex justify-end space-x-5"
        >
          <BaseButton
            as="a"
            href="/assessments"
            :is-disabled="submitting"
            :is-primary="false"
          >
            {{ $t('defaults.cancel') }}
          </BaseButton>
          <SaveButton
            v-if="!assessment.isCompleted"
            :is-loading="submitting"
            qa-class="assessments-save-button"
          >
            {{ $t('defaults.save') }}
          </SaveButton>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {
  assessmentsPath,
  assessmentPath,
  competenceModelTemplatePath,
  organisationAssessmentPath,
  organisationAssessmentsPath,
  newAssessmentObserverAssignmentPath,
  observerViewAssessmentsPath,
  newOrganisationAssessmentObserverAssignmentPath,
} from '@/util/url-helpers';
import {
  computed, defineComponent, inject, ref, watch, watchEffect, toRefs,
} from 'vue';

import usePopper from '@/util/use-popper';

import TwoColumnCard from '@/components/generic/TwoColumnCard/TwoColumnCard.vue';
import BaseInput from '@/components/generic/BaseInput/BaseInput.vue';
import BaseTextarea from '@/components/generic/BaseTextarea/BaseTextarea.vue';
import SearchableSelectList from '@/components/generic/SearchableSelectList/SearchableSelectList.vue';
import ColorPicker from '@/components/generic/ColorPicker/ColorPicker.vue';
import SingleFileUpload from '@/components/generic/SingleFileUpload/SingleFileUpload.vue';
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';
import SaveButton from '@/components/generic/SaveButton/SaveButton.vue';
import PageHeading from '@/components/generic/PageHeading/PageHeading.vue';
import Toggle from '@/components/generic/Toggle/Toggle.vue';
import RescheduleModal from '@/components/Assessments/AssessmentsSchedulePlan/RescheduleModal.vue';
import ConfirmDialogModal from '@/components/generic/ConfirmDialogModal/ConfirmDialogModal.vue';
import CompetencySelectionModal from '@/components/Assessment/CompetenceModelForm/CompetencySelectionModal.vue';
import { useI18n } from 'vue-i18n';
import ReportTemplateSelectionModal from '@/components/Templates/ReportTemplates/ReportTemplateSelectionModal.vue';
import { posthog } from 'posthog-js';
import Axios from 'axios';
import AssessmentWorkflowSettingsCard from '@/components/AssessmentWorkflowSettingsCard/AssessmentWorkflowSettingsCard.vue';
import SelectList from '@/components/generic/SelectList/SelectList.vue';
import { debounce } from 'vue-debounce';
import BrandingShowcase from '@/components/Assessments/AssessmentForm/BrandingShowcase.vue';
import AssessmentFeaturesForm from '../AssessmentFeaturesForm/AssessmentFeaturesForm.vue';
import ScheduleFields from './ScheduleFields/ScheduleFields.vue';

export default defineComponent({
  name: 'AssessmentForm',
  components: {
    TwoColumnCard,
    BaseInput,
    BrandingShowcase,
    ScheduleFields,
    BaseTextarea,
    SearchableSelectList,
    ColorPicker,
    SingleFileUpload,
    AssessmentFeaturesForm,
    BaseButton,
    SaveButton,
    PageHeading,
    Toggle,
    RescheduleModal,
    ConfirmDialogModal,
    CompetencySelectionModal,
    ReportTemplateSelectionModal,
    AssessmentWorkflowSettingsCard,
    SelectList,
  },
  props: {
    assessmentData: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    organisation: {
      type: [Object, null],
      required: true,
      default: null,
    },
    availableOrganisations: {
      type: Array,
      default: () => [],
      required: true,
    },
    availableCompetenceModels: {
      type: Array,
      required: true,
    },
    availableReportTemplates: {
      type: Array,
      default: () => [],
      required: false,
    },
    currentTenantColor: {
      type: String,
      required: true,
    },
    currentTenantLogo: {
      type: [Object, null],
      required: true,
    },
    firstEventStartDate: {
      type: String,
      required: false,
      default: null,
    },
    lastEventEndDate: {
      type: String,
      required: false,
      default: null,
    },
    currentUserIsAssignedToAssessment: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const reportTemplatesFeatureEnabled = (posthog.persistence && posthog.isFeatureEnabled('report-templates'))
    || window.moduleEnabled('reporting');

    const { organisation } = toRefs(props);

    const { t } = useI18n();
    const toast = inject('toast');

    const assessment = { ...props.assessmentData };
    const schedule = ref(props.assessmentData.schedule);

    const assessmentIsNewRecord = !assessment.id;

    const organisationSelectionDisabled = computed(() => !!props.organisation || assessment.isCompleted);

    const currentSelectedOrganisation = ref(
      assessment.organisation || props.organisation || null,
    );

    const showCompetencySelectionModal = ref(false);
    const showReportTemplateSelectionModal = ref(false);
    const selectedCompetenceModel = ref(null);
    const selectedCompetencies = ref([]);
    const selectedReportTemplates = ref([]);

    const assessmentForm = ref(null);
    const dataMethod = assessmentIsNewRecord ? 'post' : 'patch';

    const isSelectable = computed(() => {
      if (!selectedCompetenceModel.value?.competencyClusters?.length > 0) { return false; }
      for (let i = 0; i < selectedCompetenceModel.value.competencyClusters.length; i += 1) {
        if (selectedCompetenceModel.value.competencyClusters[i].competencies.length > 0) { return true; }
      }
      return false;
    });

    watch(selectedCompetenceModel, async (newSelectedCompetenceModel, _) => {
      if (newSelectedCompetenceModel?.report_template_ids) {
        selectedReportTemplates.value = newSelectedCompetenceModel?.report_template_ids;
      }

      if (newSelectedCompetenceModel?.id != null && !newSelectedCompetenceModel.competencyClusters) {
        try {
          const response = await Axios.get(competenceModelTemplatePath(newSelectedCompetenceModel.id));
          selectedCompetenceModel.value = response.data;
        } catch (error) {
          toast.error(t('components.assessment_form.errors.loading_competence_model'));
        }
      }
    });

    const saveCompetencySelection = (selection) => {
      selectedCompetencies.value = selection;
      showCompetencySelectionModal.value = false;
    };

    const getError = (...keys) => props.errors[keys.join('.')]?.[0];

    let actionUrl;
    if (assessmentIsNewRecord && props.organisation) {
      actionUrl = organisationAssessmentsPath(props.organisation);
    } else if (!assessmentIsNewRecord && props.organisation) {
      actionUrl = organisationAssessmentPath(props.organisation, assessment);
    } else if (assessmentIsNewRecord && !props.organisation) {
      actionUrl = assessmentsPath();
    } else {
      actionUrl = assessmentPath(assessment);
    }

    const competenceModelIsIndividual = !assessment.isEditable
      || (
        assessment.competenceModel.id
        && !assessment.competenceModel.isTemplate
      );

    const brandingSource = ref({
      value: assessment.brandingSource,
      label: t(`activerecord.enums.assessment.branding_source.${assessment.brandingSource}`),
    });
    const brandingIsActive = computed(() => brandingSource.value.value === 'custom');
    const brandingSources = computed(() => {
      if (currentSelectedOrganisation.value) {
        return [
          { value: 'workspace', label: t('activerecord.enums.assessment.branding_source.workspace') },
          { value: 'organisation', label: t('activerecord.enums.assessment.branding_source.organisation') },
          { value: 'custom', label: t('activerecord.enums.assessment.branding_source.custom') },
        ];
      }
      return [
        { value: 'workspace', label: t('activerecord.enums.assessment.branding_source.workspace') },
        { value: 'custom', label: t('activerecord.enums.assessment.branding_source.custom') },
      ];
    });

    watch(brandingIsActive, (isActive) => {
      if (isActive) {
        assessment.color = currentSelectedOrganisation.value?.color ?? props.currentTenantColor;
      }
    });

    watch(currentSelectedOrganisation, (newOrganisation) => {
      if (!newOrganisation && brandingSource.value.value === 'organisation') {
        brandingSource.value = (assessment.color || assessment.logo)
          ? brandingSources.value[1] : brandingSources.value[0];
      }
    });
    const currentBrandingColor = computed(() => {
      if (brandingSource.value.value === 'custom') {
        return assessment.color;
      } if (brandingSource.value.value === 'organisation') {
        return currentSelectedOrganisation.value?.color;
      }
      return props.currentTenantColor;
    });

    const currentBrandingLogo = computed(() => {
      if (brandingSource.value.value === 'custom') {
        return assessment.logo;
      } if (brandingSource.value.value === 'organisation') {
        return currentSelectedOrganisation.value?.logo;
      }
      return props.currentTenantLogo;
    });

    /*
     The system time is used for calculation, which may differ from the application time.
     There could be a problems with Daylight saving time(DTS), if it is not present in in both time zones:
     If an assessment is shifted and thus goes over the summer/winter time change,
     which was not the case before the shift,
     then it is not recognized that the period could be longer due to the time shift.(vice versa)
    */
    function isSchedulable(newSchedule) {
      return (new Date(newSchedule.endDate) - new Date(newSchedule.startDate))
                   >= (new Date(props.lastEventEndDate)
                   - new Date(props.firstEventStartDate));
    }

    const showWarningModal = ref(false);
    const showRescheduleModal = ref(false);
    const eventsRescheduleType = ref(null);

    const changeOptions = ref({ move: false, extend: false, keep: false, warning: false });
    watchEffect(() => {
      if (!assessment.id || !assessment.assessmentFeatures.scheduler || !assessment.hasAssessmentEvents) {
        changeOptions.value = { move: false, extend: false, keep: false, warning: false }; return;
      }

      eventsRescheduleType.value = null;

      // nothing changed
      if (props.assessmentData.schedule.startDate === schedule.value.startDate
        && props.assessmentData.schedule.endDate === schedule.value.endDate) {
        changeOptions.value = { move: false, extend: false, keep: false, warning: false }; return;
      }

      // startDate changed
      if (props.assessmentData.schedule.startDate !== schedule.value.startDate) {
        if (isSchedulable(schedule.value)) { // fit schedule length
          if (props.firstEventStartDate >= schedule.value.startDate) { // start is within schedule
            changeOptions.value = { move: true, extend: false, keep: true, warning: false }; return;
          }
          changeOptions.value = { move: true, extend: false, keep: false, warning: false }; return;
        } // does not fit schedule length
        changeOptions.value = { move: true, extend: true, keep: false, warning: false }; return;
      }

      // only endDate changed
      if (props.lastEventEndDate <= schedule.value.endDate) { // within schedule
        changeOptions.value = { move: false, extend: false, keep: false, warning: false }; return;
      } // not within schedule
      changeOptions.value = { move: false, extend: false, keep: false, warning: true };
    });

    const submitting = ref(false);

    function submitAssessmentForm() {
      submitting.value = true;
      assessmentForm.value.requestSubmit();
    }

    function modalOrSubmit() {
      showRescheduleModal.value = changeOptions.value.move || changeOptions.value.keep;
      showWarningModal.value = changeOptions.value.warning;
      if (!showRescheduleModal.value && !showWarningModal.value) {
        submitAssessmentForm();
      }
    }

    const selectReportTemplates = (templates) => {
      selectedReportTemplates.value = templates;
      showReportTemplateSelectionModal.value = false;
    };

    const selectedReportTemplateNames = computed(() => {
      const namesArray = selectedReportTemplates.value.map((id) => {
        const reportTemplate = props.availableReportTemplates.find((template) => template.id === id);
        return reportTemplate ? reportTemplate.name : '';
      });

      return namesArray.join(', ');
    });

    const showToolTip = ref(false);
    const setShowTooltip = debounce((value) => {
      showToolTip.value = value;
    }, 200);

    const [trigger, container] = usePopper({
      placement: 'bottom',
      strategy: 'fixed',
      modifiers: [
        { name: 'offset', options: { offset: [0, 4] } },
      ],
    });

    const newObserverAssignmentsPath = computed(() => (
      organisation.value !== null
        ? newOrganisationAssessmentObserverAssignmentPath(organisation.value, assessment)
        : newAssessmentObserverAssignmentPath(assessment)));

    return {
      submitting,
      assessmentIsNewRecord,
      assessment,
      organisationSelectionDisabled,
      dataMethod,
      actionUrl,

      showWarningModal,
      showRescheduleModal,
      showCompetencySelectionModal,
      showReportTemplateSelectionModal,

      competenceModelIsIndividual,
      brandingIsActive,
      brandingSources,
      brandingSource,
      currentBrandingLogo,
      currentBrandingColor,
      changeOptions,
      eventsRescheduleType,
      assessmentForm,
      schedule,

      currentSelectedOrganisation,

      selectedCompetenceModel,
      isSelectable,
      selectedCompetencies,
      selectedReportTemplates,
      selectedReportTemplateNames,

      getError,
      modalOrSubmit,
      submitAssessmentForm,
      saveCompetencySelection,
      selectReportTemplates,
      reportTemplatesFeatureEnabled,

      showToolTip,
      setShowTooltip,
      trigger,
      container,
      newObserverAssignmentsPath,
      observerViewAssessmentsPath,
    };
  },
});
</script>
